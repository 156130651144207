
import Vue from 'vue'
import BoosterPicture from '../../node_modules/nuxt-booster/runtime/components/BoosterPicture.vue'
export default Vue.extend({
  name: 'Banner',
  components: {
    BoosterPicture
  },
  props: {
    banner: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    getMedia(url) {
      if (url) {
        const parts = url.split('/')
        const updatedUrl = parts.slice(4).join('/')
        return '/' + updatedUrl // + '?updateTs=' + date
      } else {
        return ''
      }

      // step example
      // url : https://s3.gcp.cving.com/s3/home_page/banner/4/desktop/media/blob
      // parts : ["https:", "", "s3.gcp.cving.com", "s3", "home_page", "banner", "4", "desktop", "media", "blob"]
      // updatedUrl : home_page/banner/4/desktop/media/blob
      // return : /home_page/banner/4/desktop/media/blob?updateTs=1631612400000
    },
    openLink(link) {
      const newWindow = window.open(link, '_blank')
      if (newWindow) {
        newWindow.opener = null // Evita che la finestra aperta possa accedere all'opener
        newWindow.focus() // Porta la nuova scheda in primo piano
      } else {
        // Gestisci il blocco dei popup
        alert('I popup sono bloccati dal tuo browser. Abilita i popup per aprire il link.')
      }
    },
    track () {
      this.$gtm.push({
        event: 'click_event',
        event_data: {
          event_name: this.title,
          event_url: this.link,
          is_special: false,
          page_url: this.$router.currentRoute.fullPath,
          page_title: this.title
        }
      })
    }
  }
})
