
export default {
  components: {
    Checkbox: () => import('primevue/checkbox'),
    InputText: () => import('primevue/inputtext'),
    Button: () => import('primevue/button')
  },
  data() {
    return {
      name: '',
      email: '',
      loading: false,
      submitTried: false,
      terms: false,
      done: false,
      error: false,
      title: '',
      text: ''
    }
  },
  methods: {
    validateEmail(email) {
      if (!email || email === '') {
        return false
      }

      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null
    },
    async subscribe() {
      this.loading = true
      this.done = this.error = false
      this.submitTried = true

      if (this.terms && this.email && this.name && this.validateEmail(this.email)) {
        try {
          const response = await this.$axios.post('/proxysbapi/home/b2c-newsletter-subscription', {
            email: this.email,
            name: this.name
          })

          if (response.status === 200) {
            this.title = this.$t('newsletter.thanksForSubscription')
            this.text = this.$t('newsletter.checkEmail')
          }
        } catch (error) {
          console.error('Errore durante la richiesta:', error)

          const status = error.response?.status
          this.title = status === 409 ? this.$t('newsletter.thanks') : this.$t('newsletter.errorTitle')
          this.text = status === 409 ? this.$t('newsletter.alreadySubscribed') : this.$t('newsletter.errorText')
          this.error = status !== 409
        } finally {
          this.done = true
          this.name = ''
          this.email = ''
          this.terms = false
          this.submitTried = false
        }
      }
      setTimeout(() => {
        this.loading = false
      }, 800)
    }
  }
}
