import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6278cfaa"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=6278cfaa&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LaunchBox: require('/usr/src/app/components/blocks/LaunchBox.vue').default,SsrCarousel: require('/usr/src/app/node_modules/vue-ssr-carousel/index.js').default,Banner: require('/usr/src/app/components/blocks/Banner.vue').default,Header: require('/usr/src/app/components/Header.vue').default,Opportunity: require('/usr/src/app/components/blocks/Opportunity.vue').default,Company: require('/usr/src/app/components/blocks/Company.vue').default,Social: require('/usr/src/app/components/blocks/Social.vue').default,Event: require('/usr/src/app/components/blocks/Event.vue').default,News: require('/usr/src/app/components/blocks/News.vue').default})
