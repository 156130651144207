
import Vue from 'vue'
import BoosterPicture from '../../node_modules/nuxt-booster/runtime/components/BoosterPicture.vue'
export default Vue.extend({
  name: 'Event',
  components: {
    BoosterPicture,
    Button: () => import('primevue/button')
  },
  props: {
    event: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getFormattedDate() {
      const startDate = this.event.startDate + 'Z' // appending 'Z' to date string will make dayjs understand that the date is UTC and convert it properly to user timezone
      const dayOfWeek = this.$dayjs(startDate).locale(this.$i18n.locale).format('dddd')
      const formattedTime = this.$dayjs(startDate).locale(this.$i18n.locale).format('HH:mm')
      return formattedTime ? this.$t('blocks.timeFormat', { day: dayOfWeek, time: formattedTime }) : ''
    },
    eventTag() {
      if (this.event.info) {
        switch (this.event.info) {
        case 2:
          // Restituisci il messaggio che i posti stanno per terminare
          return this.$t('blocks.spotsAlmostGone')
        case 3:
          // Restituisci il messaggio Sold Out
          return this.$t('blocks.soldOut')
        default:
          const startDate = this.$dayjs(this.event.startDate)
          const today = this.$dayjs()
          const diffHours = startDate.diff(today, 'hours')
          const diffDays = Math.round(diffHours)
          if (diffDays > 0) {
            return this.$tc('blocks.daysRemaining', diffDays, { count: diffDays })
          }
        }
      }
      return null
    }
  },
  methods: {
    getMedia(url, date) {
      const parts = url.split('/')
      const updatedUrl = parts.slice(4).join('/')
      return '/' + updatedUrl + '?updateTs=' + date
    },
    openLink(link) {
      this.track()
      // Apri il link in una nuova scheda del browser
      const newWindow = window.open(link, '_blank')
      if (newWindow) {
        newWindow.opener = null // Evita che la finestra aperta possa accedere all'opener
        newWindow.focus() // Porta la nuova scheda in primo piano
      } else {
        // Gestisci il blocco dei popup
        alert('I popup sono bloccati dal tuo browser. Abilita i popup per aprire il link.')
      }
    },
    track () {
      this.$gtm.push({
        event: 'click_event',
        event_data: {
          event_name: this.event?.title,
          event_url: this.event?.link,
          is_special: false,
          page_url: this.$router.currentRoute.fullPath,
          page_title: document ? document?.title : 'CVing'
        }
      })
    }
  }
})
